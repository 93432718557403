
import { defineComponent, reactive, onMounted, ref } from "vue";
import HolidayDetailCard from "@/components/ListCard/HolidayDetailCard.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { useRouter, useRoute } from "vue-router";
import { LeaveTypeService } from "@/services/attendance/leavetype/LeaveTypeService";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import moment from "moment";
import { nextTick } from "process";
import NoDate from "@/components/NoData.vue";

export default defineComponent({
  name: "MyHolidayDetailList",
  components: {
    Header,
    CardLoading,
    HolidayDetailCard,
    NoDate
  },
  setup() {
    const leaveTypeService = new LeaveTypeService();
    const api = new VacationLeaveService();
    const router = useRouter();
    const params = useRoute().params;
    let islist = ref(true); //是否显示加载中的标识
    const holidayList = reactive({ data: new Array() });
    const moduleList = reactive({ data: new Array() });
    const showNoDate=ref(true)
    const searchParams = reactive({
      limit: 10,
      offset: 1,
      leaveTypeId: params.leaveTypeId,
      years: params.years,
      userId: params.userId,
    });
    const selSeg = ref("");

    const initData = () => {
      resetPage();
    };

    //滚动到顶部
    const scrollToTop = () => {
      nextTick(() => {
        let ionContent: any = document.querySelector(
          "#MyHolidayDetailListContent"
        );
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    };
    //重新从第一页获取
    const resetPage = () => {
      searchParams.offset = 1;
      getListRequest((res: any) => {
        holidayList.data = res; 
         showNoDate.value = res == null || res.length <= 0;
      });
    };
    const nextPage = (event: any) => {
      searchParams.offset++;
      getListRequest(
        (result: any) => {
          holidayList.data = holidayList.data.concat(result);
          setTimeout(() => {
            event.target.complete();
          }, 1000);
        },
        () => {
          event.target.complete();
        }
      );
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      let params = {
        page: searchParams.offset,
        size: searchParams.limit,
        leaveTypeId: searchParams.leaveTypeId,
        years: searchParams.years,
        userId: searchParams.userId,
      };
      api
        .getMyhistory(params)
        .then((res: any) => {
          callback(res);
          islist.value = false;
        })
        .catch(() => {
          islist.value = false;
          errCalback();
        });
    };

    onMounted(() => {
      initData();
    });
    return {
      doRefresh,
      nextPage,
      islist,
      holidayList,
      moduleList,
      selSeg,
      params,
      showNoDate
    };
  },
});
