
import { defineComponent } from "vue";
import AvatarList from "@/components/AvatarList.vue";
import moment from "moment";

export default defineComponent({
  name: "HolidayDetailCard",
  components: { AvatarList },
  props: {
    HolidayDetailCardObj: Object,
  },
  emits: [],
  data() {
    return {};
  },
  methods: {
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.userName, img: "", id: item.userId };
      users.push(obj);
      return users;
    },
    formatApplyTime(item: any) {
      return moment(item.applyTime).format("yyyy-MM-DD HH:mm");
    },
    formatTime(item: any) {
      let val = "";
      if (item.startDate != null && item.endDate != null) {
        if (item.startNoon == "AM" && item.endNoon == "PM") {
          val = item.startDate + "至" + item.endDate;
        } else {
          val =
            item.startDate +
            (item.startNoon == "AM" ? "上午" : "下午") +
            "至" +
            item.endDate +
            (item.endNoon == "AM" ? "上午" : "下午");
        }
      }
      return val;
    },
  },
});
